import React from 'react';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Seo from "../components/Seo";
import carlyHeadshot from "../images/carlybartel.png";

const About = () => {
  return (
    <Layout pageName="about">
        <Seo
                title={'About'}
        />
      <Container>
          <section>
              <div className="extra-large-wrapper sm:flex justify-between pt-4 sm:pt-12 pb-16 sm:pb-32">
                  <div>
                      <div className="sm:max-w-3xl">
                          <h1 className="text-3xl sm:text-5xl leading-tight mt-8">I’m Carly, a designer and creative developer passionate about creating beautiful, goal-oriented brands and websites.</h1>
                      </div>
                      <div className="sm:max-w-2xl mt-4">
                          <p className="">I’ve spent ten years working in the industry spanning web design, frontend development, UX/UI design and brand identities. Currently, I am Director of Design at <a href="https://www.heartex.com/" target="_blank" rel="noreferrer" className="underline hover:text-green">Heartex</a>, where I help guide and execute designs across the organization.</p>
                          <p className="mt-4">On the weekends I can most likely be found re-painting ~another~ room in my home in Cleveland, Ohio, <a href="https://www.instagram.com/thecurrentreader/" rel="noreferrer" target="_blank" className="underline hover:text-green">reading a good book</a>, or building some furniture.</p>
                          <div className="border-t mt-12 pt-3">
                              <h3 className="font-mono text-xs italic">email</h3>
                              <a href="mailto:carlybartel@gmail.com" className="text-xl sm:text-3xl mt-5 hover:text-green">carlybartel@gmail.com -></a>
                          </div>
                          <div className="border-t mt-12 pt-3">
                              <h3 className="font-mono text-xs italic">connect</h3>
                              <div className="block mt-2"><a href="https://www.linkedin.com/in/carlybartel/" rel="noreferrer" target="_blank" className="text-xl sm:text-3xl hover:text-green">LinkedIn -></a></div>
                          </div>
                      </div>
                  </div>
                  <div className="mt-8 sm:mt-0">
                      <img src={carlyHeadshot} alt="" className="w-full sm:w-auto" data-0="transform:rotate(5deg);" data-400="transform:rotate(-3deg);"/>
                  </div>
              </div>
          </section>
          <section className="bg-black">
              <div className="text-green extra-large-wrapper space-container relative py-16 sm:py-32">
                  <h3 className="font-mono text-xs italic">a brief timeline</h3>
                  <ul className="sm:grid-cols-2 lg:grid-cols-3 grid mt-12 sm:gap-x-12 gap-y-12 sm:gap-y-24">
                      <li className="sample-card border-t pt-4">
                          <h3 className="text-4xl sm:text-5xl font-serif">Director of Design</h3>
                          <h4 className="text-xl sm:text-2xl mt-2">Heartex</h4>
                          <span className="text-xl sm:text-2xl">08.2022 — Present</span>
                      </li>
                      <li className="sample-card border-t pt-4">
                          <h3 className="text-4xl sm:text-5xl font-serif">Head of Design</h3>
                          <h4 className="text-xl sm:text-2xl mt-2">ZoomInfo</h4>
                          <span className="text-xl sm:text-2xl">02.2022 — 08.2022</span>
                      </li>
                      <li className="sample-card border-t pt-4">
                          <h3 className="text-4xl sm:text-5xl font-serif">Senior Team Lead, Web Design</h3>
                          <h4 className="text-xl sm:text-2xl mt-2">ZoomInfo</h4>
                          <span className="text-xl sm:text-2xl">04.2019 — 02.2022</span>
                      </li>
                      <li className="sample-card border-t pt-4">
                          <h3 className="text-4xl sm:text-5xl font-serif">UI/UX Designer and Developer</h3>
                          <h4 className="text-xl sm:text-2xl mt-2">NeverBounce</h4>
                          <span className="text-xl sm:text-2xl">07.2017 — 04.2019</span>
                      </li>
                      <li className="sample-card border-t pt-4">
                          <h3 className="text-4xl sm:text-5xl font-serif">Lead UX Designer and Frontend Developer</h3>
                          <h4 className="text-xl sm:text-2xl mt-2">thunder::tech Marketing Agency</h4>
                          <span className="text-xl sm:text-2xl">03.2014 — 07.2017</span>
                      </li>
                      <li className="sample-card border-t pt-4">
                          <h3 className="text-4xl sm:text-5xl font-serif">BFA in Biomedical Art</h3>
                          <h4 className="text-xl sm:text-2xl mt-2">The Cleveland Institute of Art</h4>
                          <span className="text-xl sm:text-2xl">2009 — 2013</span>
                      </li>
                  </ul>
              </div>
          </section>
          <section className="bg-green py-16 sm:py-32">
              <div className=" text-black extra-large-wrapper">
                  <h3 className="font-mono text-xs italic">my favorite things</h3>
                  <ul className="mt-12">
                      <li className="text-5xl sm:text-7xl font-serif mt-4 pt-4 border-t">Books</li>
                      <li className="text-5xl sm:text-7xl font-serif mt-4 pt-4 border-t">going to bed early</li>
                      <li className="text-5xl sm:text-7xl font-serif mt-4 pt-4 border-t">indian food</li>
                      <li className="text-5xl sm:text-7xl font-serif mt-4 pt-4 border-t">reality tv</li>
                      <li className="text-5xl sm:text-7xl font-serif mt-4 pt-4 border-t">interior decorating</li>
                  </ul>
              </div>
          </section>
          <section>
              <div className="extra-large-wrapper relative mt-16">
                  <div className="marquee-wrapper">
                      <div className="marquee">
                          <p className="text-6xl sm:text-9xl font-serif">&nbsp;thanks for stopping by —</p>
                          <p className="text-6xl sm:text-9xl font-serif">&nbsp;thanks for stopping by —&nbsp; </p>
                          <p className="text-6xl sm:text-9xl font-serif">&nbsp;thanks for stopping by —&nbsp; </p>
                      </div>
                  </div>
              </div>
          </section>
      </Container>
    </Layout>
  );
};

export default About;
